<template>
  <div v-padding:top="46">
    <van-nav-bar title="一企一档申报" fixed left-arrow @click-left="goBack">
    </van-nav-bar>
    <van-form @submit="onSubmit" :show-error="false">
      <van-cell-group>
        <upload-file
          :uuid="fileUUID"
          ref="uploadFile"
          :type="$route.query.operate"
        ></upload-file>
      </van-cell-group>
      <van-cell-group title="手动签名">
        <van-cell title=" ">
          <template slot="default">
            <div class="sign-img">
              <img
                :src="signData.imgUrl"
                v-if="signData.imgUrl"
                alt=""
                @click="onReSign()"
              />
            </div>
          </template>
          <template slot="right-icon">
            <van-button
              type="primary"
              size="mini"
              @click="onSign"
              v-if="!signData.imgUrl && $route.query.operate == 'add'"
              native-type="button"
              >签名</van-button
            >
          </template>
        </van-cell>
      </van-cell-group>

      <sign-modal v-model="signModalObj" @confirm="onSignConfirm"></sign-modal>
      <van-cell-group
        title="审核信息"
        class="edit-div"
        v-if="$route.query.operate == 'edit' && formData.status != '1'"
      >
        <van-cell value=" ">
          <template #title>
            <span class="custom-title">审核结果</span>
            <van-tag
              :type="formData.value == '通过' ? 'success' : 'danger'"
              style="margin-left: 42px"
              size="medium"
              >{{ formData.value }}
            </van-tag>
          </template>
        </van-cell>
        <van-cell title="备注" :value="formData.remark"> </van-cell>
        <!-- <van-field
          readonly
          name="remark"
          v-model="formData.remark"
          type="textarea"
          label="备注"
        /> -->
      </van-cell-group>
      <van-row>
        <van-col span="24" v-if="this.$route.query.operate == 'add'">
          <van-button
            block
            type="primary"
            native-type="submit"
            :loading="submitLoading"
          >
            保存
          </van-button>
        </van-col>
      </van-row>
    </van-form>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import uploadFile from "@/components/common/upload-file.vue"; // 公共文件上传
import signModal from "@/components/common/sign.vue"; // 公共签名

export default {
  components: { signModal, uploadFile },
  props: {},
  data() {
    return {
      enterpriseId: localStorage.getItem("enterpriseId"),
      file: [],
      uploadedFile: [],
      fileType: [],
      submitLoading: false,
      calendarShow: false, // 日历
      calendarType: "", // 时间类型
      defaultDate: this.$moment().toDate(),
      signatureUuid: "", // 签名uuid
      signData: {
        imgUrl: "",
        file: "",
        uuid: "",
      },
      fileUUID: "", // 附件uuid
      signModalObj: {},
      formData: {
        status: "",
        value: "",
        remark: "",
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    if (this.$route.query.operate == "edit") {
      this.getsMonitorinspectedInfo();
    } else {
      this.getFileUUID();
    }
  },
  methods: {
    // 新增一企一档信息申报
    async addMonitorinspectedInfo() {
      this.submitLoading = true;
      if (this.$refs.uploadFile.files.length == 0) {
        this.submitLoading = false;
        Toast.fail("请上传附件图片");
        return false;
      }
      if (!this.signData.file) {
        this.submitLoading = false;
        Toast.fail("请进行签名");
        return false;
      }
      await this.$refs.uploadFile.onSubmit().catch((err) => {
        this.submitLoading = false;
        Toast.fail(`上传附件失败：${err.msg}`);
        throw err;
      });

      await this.getUUID().catch((err) => {
        this.submitLoading = false;
        Toast.fail("获取uuid失败，请重试");
        throw err;
      });

      await this.uploadFile().catch((err) => {
        this.submitLoading = false;
        Toast.fail(`上传签名失败:${err.msg}`);
        throw err;
      });
      const data = {
        relatedId: this.$route.query.id,
        fileUuid: this.fileUUID, // 附件id
        signatureUuid: this.signData.uuid,
        type: 2,
        // 签名id
      };
      this.$api.setting
        .postAddInformationReview(data)
        .then((res) => {
          if (res.data.state == 0) {
            Toast.success("新增成功");
            this.goBack();
          }
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },
    // 重新签名
    onReSign() {
      if (this.$route.query.operate == "add") {
        Dialog.confirm({
          title: "重新签名",
          message: "是否重新签名",
        })
          .then(() => {
            this.onSign();
          })
          .catch(() => {});
      }
    },
    // 签名
    onSign() {
      this.signModalObj = {
        title: "签名",
        isShow: true,
      };
    },

    // 完成签名
    onSignConfirm(signData) {
      this.signData = {
        imgUrl: signData.imgDataBase,
        file: signData.file,
      };
    },
    // 获取uuid
    getUUID() {
      return new Promise((resolve, reject) => {
        if (!this.signData.file) {
          resolve();
        } else {
          this.$api.common
            .getUuid()
            .then((res) => {
              if (res.data.state == 0) {
                this.signData.uuid = res.data.data;
                resolve();
              } else {
                reject();
              }
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },
    // 获取附件uuid
    getFileUUID() {
      this.$api.common.getUuid().then((res) => {
        if (res.data.state == 0) {
          this.fileUUID = res.data.data;
        }
      });
    },
    onSubmit() {
      this.addMonitorinspectedInfo();
    },
    // 附件上传
    uploadFile() {
      return new Promise((resolve, reject) => {
        if (!this.signData.file) {
          resolve();
        } else {
          const formData = new FormData();
          formData.append("file", this.signData.file);
          formData.append("uuid", this.signData.uuid);
          this.$api.common
            .postUploadUuid(formData)
            .then((res) => {
              if (res.data.state == 0) {
                resolve();
              } else {
                reject();
              }
            })
            .catch((err) => {
              reject(err);
            });
        }
      });
    },
    // 获取一企一档信息
    getsMonitorinspectedInfo() {
      const data = {
        id: this.$route.query.id,
      };
      this.$api.setting.getInformationReviewById(data).then((res) => {
        if (res.data.state == 0) {
          this.formData = {
            remark: res.data.data.remark,
            status: res.data.data.status,
            value: res.data.data.status == "2" ? "通过" : "不通过",
          };
          this.fileUUID = res.data.data.fileUuid;
          if (res.data.data.signatureUuid) {
            this.getSignFile(res.data.data.signatureUuid);
          }
        }
      });
    },
    // 获取签名图片
    getSignFile(signatureUuid) {
      const data = {
        uuid: signatureUuid,
      };
      this.$api.common.getFileByUuid(data).then((res) => {
        if (res.data.state == 0) {
          this.signData.imgUrl =
            this.$api.base.file + "/" + res.data.data[0].ossFileName;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.sign-img {
  width: 150px;
  height: 75px;
  img {
    width: 100%;
    height: 100%;
  }
}
.edit-div {
  .van-cell__title {
    max-width: 43%;
    text-align: left;
  }
  .van-cell__value {
    min-width: 57%;
    margin-left: -48px;
    span {
      display: inline-block;
      text-align: left;
      word-break: break-all;
    }
  }
}
</style>
